import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function useQueryParams() {

    const location = useLocation()
    const [queryParams, setQueryParams] = useState(extractParams(location))

    useEffect(() => {
        setQueryParams(extractParams(location))
    }, [location])


    function extractParams(location) {
        let params = {}
        new URLSearchParams(location.search).forEach((value, key) => {
            params[key] = value
        })
        return params
    }

    return {queryParams}
}

export default useQueryParams