import Logger from 'loglevel'

let originalFactory = Logger.methodFactory;
Logger.methodFactory = function (methodName, logLevel, loggerName) {
  let rawMethod = originalFactory(methodName, logLevel, loggerName);

  return console.log.bind(console)
};
Logger.setLevel(Logger.levels.INFO)

Logger.get = Logger.getLogger
Logger.get('OfficeEditor').setLevel(Logger.levels.WARN)
Logger.get('OfficeLayoutEditor').setLevel(Logger.levels.WARN)
Logger.get('OfficeMap').setLevel(Logger.levels.WARN)


export default Logger