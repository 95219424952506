import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {v4 as uuid} from 'uuid'
import Logger from '../services/logger_service'

import {view, store} from '@risingstack/react-easy-state'
import OfficeLayoutEditor from './office_editor/office_layout_editor'
import OfficeConnectionEditor from './office_editor/office_connection_editor'


const GRID = 12
const [WIDTH, HEIGHT] = [500, 400]
const round = Math.round
const logger = Logger.get('OfficeEditor')

function OfficeEditor(props) {

  const params = useParams()
  const state = store({office: {rooms: []}, action: {}, position: [0,0]})
  const localState = store({mode: 'layout'})

  useEffect(() => {
    refresh()
    window.addEventListener('mouseup', (e) => {
      logger.info('clear')
      state.action = {}
    })
  },[])

  function refresh() {
    let token = params.token
    axios.get(`/api/offices/${token}`)
    .then(({data}) => {
      state.office = transform(data)
      logger.info(state)
    })
  }

  function transform(office) {
    let idMap = {}
    office.rooms.forEach((room) => {
      room.uuid = uuid()
      idMap[room.id] = room.uuid
    })

    office.rooms.forEach((room) => {
      room.from_rooms = (room.from_rooms || []).map((id) => {return idMap[id]})
    })

    logger.info(office)
    return office
  }

  function save() {
    let rooms = state.office.rooms.map((room) => {
      return {
        uuid: room.uuid,
        dimensions: room.dimensions.map(dim => round(dim)),
        from_rooms: room.from_rooms
      }
    })

    axios.patch(`/api/offices/${params.token}`, {rooms})
    .then(({data}) => {
      state.office = transform(data)
      logger.info(state)
    })
  }

  function setMode(mode) {
    localState.mode = mode
  }


  return <>
    <div>position: [{state.position[0]},{state.position[1]}]</div>
    {localState.mode === 'layout' ?
      <OfficeLayoutEditor state={state} position={state.position}></OfficeLayoutEditor>
      : null
    }
    {localState.mode === 'connection' ?
      <OfficeConnectionEditor state={state} position={state.position}></OfficeConnectionEditor>
      : null
    }
    <div>
      <button onClick={save}>Save</button>
      {localState.mode === 'layout' ?
        <button className="button-outline" onClick={() => setMode('connection')}>Connections</button>
        :
        <button className="button-outline" onClick={() => setMode('layout')}>Layout</button>
      }
    </div>
    <div>
      {state.office.rooms.map((room) => {
        return <pre key={room.uuid}>{JSON.stringify(room.dimensions)}</pre>
      })}

    </div>
  </>
}

export default view(OfficeEditor)