import React from 'react'
import {useRef} from 'react'
import {view, store} from '@risingstack/react-easy-state'
import './chat_room.scss'

import useOffice from '../services/office_service'

function ChatRoom(props) {

    const container = useRef()
    const state = store({newMessage: ''})

    const {messages, sendMessage} = useOffice()

    function onSendMessage(e) {
        if (e.key === 'Enter') {
            sendMessage(state.newMessage)
            state.newMessage = ''
            let parent = container.current.parentElement
            console.log('parent', [parent])
            parent.scrollTop = parent.scrollHeight
        }
    }

    return <div ref={container}>
        <ul className="chat-list" style={{wordWrap: 'break-word'}} >
            {messages.map((message, index) => {
                return <li key={index}>{message}</li>
            })}
        </ul>
        <input
          placeholder="Write messages here"
          type="text"
          style={{maxWidth: '50rem'}}
          value={state.newMessage}
          onChange={(e) => state.newMessage = e.target.value}
          onKeyDown={onSendMessage} />

    </div>
}

export default view(ChatRoom)