import axios from 'axios'
import _ from 'lodash'
import {useState, useEffect} from 'react'
import ActionCable from 'actioncable'
import {toast} from "react-toastify";
import useLogin from "./login_service";
import IndexArray from "index-array";
import Logger from './logger_service'

import {store} from '@risingstack/react-easy-state'

import play from './sound_service'


const officeState = store({
    messages: [],
    persons: new IndexArray(),
    channel: null,
    rooms: new IndexArray()
})

const logger = Logger.get('OfficeService')


function useOffice(master = false) {

    const {user} = useLogin()
    // TODO: clean this up. Exporting the same values twice.
    let publicFields = {
        officeState,
        persons: officeState.persons,
        setMyPosition,
        ping,
        messages: officeState.messages,
        sendMessage,
        rooms: officeState.rooms,
        snapshot: snapshot,
    }

    if (master) {

        const [isConnected, setConnected] = useState(false)

        function connect(token) {
            let newRooms
            axios.get(`/api/offices/${token}`)
            .then((response) => {
                newRooms = new IndexArray(...response.data.rooms)
                officeState.rooms = newRooms
            })
            .then(() => {
                let cable = ActionCable.createConsumer(`/cable`)
                let newChannel = cable.subscriptions.create(
                  {channel: 'OfficeChannel', token: token},{
                      received: (data) => { updateOffice(data, newRooms) },
                      initialized: () => {logger.info('initialized')},
                      connected: () => {setConnected(true)},
                      disconnected: () => {logger.info('disconnected')},
                      rejected: () => {logger.info('rejected')},
                  })
                officeState.channel = newChannel
            })

        }

        function disconnect() {
            officeState.channel && officeState.channel.unsubscribe()
            officeState.persons = new IndexArray()
            setConnected(false)
            officeState.channel = null
        }


        return {...publicFields, connect, disconnect, isConnected}
    }


    function updateOffice(data, rooms) {

        switch(data.action) {
            case 'load':
                officeState.persons = withDistances(new IndexArray(...data.persons), rooms)
                break
            case 'ping':
                toast(`${data.from.name} pinged you!`)
                play('ping')
                break
            case 'moved':
                officeState.persons = ((old) => {
                    let result = old
                    let person = old.fetch({id: data.id})
                    if (person) {
                        person.position = data.position
                        person.room_id = data.room_id
                        result = old.clone()
                    } else {
                        result = old.add({id: data.id, name: data.name,
                            position: data.position, room_id: data.room_id}).clone()
                    }
                    return withDistances(result, rooms)
                })(officeState.persons)
                break
            case 'left':
                officeState.persons = officeState.persons.remove({id: data.id}).clone()
                break
            case 'message':
                officeState.messages.push(data.message)
                break
            case 'snapshot':
                axios.post('/api/snapshots', {
                    persons: officeState.persons,
                    hundredState: window.hundredState
                })
                break
            default:
                console.error(`Unknown action '${data.action}' received from office channel.`)
        }

    }


    function setMyPosition(pos) {
        let coord = pos.map(coord => Math.round(coord))
        officeState.channel.send({action: 'move', position: coord})
    }


    function withDistances(persons, rooms) {
        let currentPerson = persons.fetch({id: user.person_id})
        if (!currentPerson) {
            return persons
        }
        let currentRoom = rooms.fetch({id: currentPerson.room_id})

        if (!currentRoom) {
            logger.warn('could not find current room', [rooms, currentPerson])
            // TODO: need to return gracefully
        }
        persons.forEach((person) => {
            person.distance = distance(person.position, currentPerson.position)
            if (person.id == user.person_id) {
                person.subscribed = false
            } else if (currentRoom.id === person.room_id) {
                person.subscribed = true
            } else if (currentRoom.from_rooms.indexOf(person.room_id) >= 0) {
                person.subscribed = true
                person.distance = person.distance/100
            } else {
                person.subscribed = false
            }
        })

        return persons
    }

    function ping(person) {
        officeState.channel.send({action: 'ping', id: person.id})
        toast(`You pinged ${person.name}!`)
    }

    function sendMessage(message) {
        officeState.channel.send({action: 'message', message: message})
    }

    function snapshot() {
        officeState.channel.send({action:'snapshot'})
    }

    return publicFields
}



function distance(pos1, pos2) {
    if (!pos1 || !pos2) {
        return 0
    }
    return Math.sqrt((pos1[0]-pos2[0])**2 + (pos1[1]-pos2[1])**2)
}


export default useOffice