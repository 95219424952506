import React, { useState, useEffect } from "react"
import { view, store } from '@risingstack/react-easy-state'
import { usePopper } from "react-popper"

import './remote_video_popup.scss'
import c from "classnames";
import Icon from "./icon";

function RemoteVideoPopup(props) {

  // Ugly popper stuff
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{name: 'offset', options: {offset: [0,8]}}],
    placement: 'top'
  });



  const state = store({volume: 0, visible: false})

  function onVolumeChange(e) {
    state.volume = e.target.value
    props.onVolumeChange && props.onVolumeChange(state.volume)
  }
  function onPing() {
    props.onPing && props.onPing()
  }

  function toggleVisible() {
    state.visible = !state.visible
  }


  return <>
    <button className="icon options" ref={setReferenceElement} onClick={toggleVisible}>
      <Icon icon="ellipsis-v" />
    </button>

    {state.visible ?
      <div className="popup-menu" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div onClick={onPing}>Ping</div>
        <div>
          <span className="volume-icon">
            <Icon icon="volume" />
          </span>
          <input className="volume-slider" min="-40" max="6" type="range" value={state.volume} onChange={onVolumeChange}/>
        </div>
      </div>
      : null
    }
  </>
}



export default view(RemoteVideoPopup)