import React from 'react'
import {useState} from 'react'

import './register.scss'
import axios from "axios";
import {toast} from "react-toastify";

const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

function setField(setter, field) {
  return (e) => {
    setter((old) => {
      old[field] = e.target.value
      return {...old}
    })
  }
}


function Register() {


  const[name, setName] = useState('')
  const[email, setEmail] = useState('')
  const[password, setPassword] = useState('')
  const[confirmPassword, setConfirmPassword] = useState('')


  function onRegister() {
    let errors = validateFields()
    if (!_.isEmpty(errors)) {
      for (let key in errors) {
        toast.error(errors[key])
      }
    } else {
      axios.post('/users', {user: {name, email, password, password_confirmation: confirmPassword }})
      .then((response) => {
        clearForm()
        if (response.data.id) {
          toast('You have successfully registered for an account. \nYou will be contacted when it is activated.')
        } else {
          toast('We were unable to create an account.')
        }
      })
    }
  }

  function clearForm() {
    setName('')
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  }

  function validateFields() {
    let errors = {}
    if (name.length < 3) errors.name = 'Your name must have at least 3 characters.'
    if (!email.match(EMAIL_REGEX)) errors.email = 'The email address you entered is invalid.'
    if (password !== confirmPassword) {
      errors.confirmPassword = 'The two entered passwords must match.'
    }
    if (password.length < 12) errors.password = 'Your password must have at least 12 characters.'

    return errors
  }

  return <div className="registration-form" style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
    <div style={{height: '20%'}}></div>
    <fieldset style={{alignSelf: 'center'}}>
      <label>Display Name</label>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)} />

      <label>Email Address</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)} />

      <label>Password</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)} />

      <label>Confirm Password</label>
      <input
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)} />


      <div>
        <div style={{flexGrow: 1}}/>
        <button onClick={onRegister}> Register </button>
      </div>
    </fieldset>

  </div>


}


export default Register