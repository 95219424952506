import React, {useEffect, useRef} from 'react'
import './remote_video.scss'
import {view, store, autoEffect} from '@risingstack/react-easy-state'

import RemoteVideoPopup from "./remote_video_popup";
import useOffice from "../services/office_service";
import Logger from '../services/logger_service'
import {setVolumes, useHundred} from '../services/hundred_service'

const logger = Logger.get('RemoteVideo')

function NewRemoteVideo(props) {

  let video = useRef()
  const {attachVideo, setVolume} = useHundred()

  const state = store({
    name: '',
    volume: 1,
    person: null,
    participant: props.participant
  })
  const {persons, ping} = useOffice()

  useEffect(() => {
    state.participant = props.participant
  },[props.participant])

  useEffect(() => {
    state.person = persons.fetch({id: state.participant.identity})
    state.name = state.person?.name
    if (state.person?.name) { // Still need to check name?
      state.volume = volumeProfile(state.person.distance, state.person.volumeOffset)
    }
  }, [persons])

  autoEffect(() => {
    if (state.participant?.videoTrack) {
      attachVideo(state.participant.videoTrack, video.current)
    }
  })

  autoEffect(() => {
    if (state.participant?.audioTrack) {
      setVolumes(state.participant.audioTrack, state.volume, 'autoEffect', state.participant.name)
      setVolume(state.volume, state.participant.audioTrack)
    }
  })



  function onUnmount() {
    if (state.participant.audioTrack) {
      setVolumes(state.participant.audioTrack, 0, 'unmount', state.participant.name)
      setVolume(0, state.participant.audioTrack)
    } else {
      console.warn('trying to set volume to 0 of missing track for participant ' + state.participant.identity)
    }
  }

  useEffect(() => {
    return () => {
      onUnmount()
    }
  }, [])



  function pingRemotePerson() {
    let remotePerson = persons.fetch({id: parseInt(state.participant.identity)})
    ping(remotePerson)
  }

  function adjustVolume(value) {
    let remotePerson = persons.fetch({id: parseInt(state.participant.identity)})
    remotePerson.volumeOffset = value
    state.volume = volumeProfile(remotePerson.distance, remotePerson.volumeOffset)
  }

  return <div className="remote-video">

    <div className="video-overlay"
         style={{backgroundColor: `rgba(0,0,0,${opacity(state.volume)}`}}
    >
      <div className="overlay-text">{state.name}</div>
      <div className="overlay-text">v={`${decibels(state.volume)} dB`}</div>
      <div style={{flexGrow: 1, pointerEvents: 'none'}}></div>
      <div style={{display: 'flex'}}>
        <div style={{flexGrow: 1}}></div>
        <RemoteVideoPopup
          onPing={pingRemotePerson}
          onVolumeChange={adjustVolume}
        >
        </RemoteVideoPopup>
      </div>
    </div>

    <div className="video-background" />
    <div>
      <video
        ref={video}
        autoPlay
        muted
        playsInline
      />
    </div>
  </div>
}


function opacity(volume) {
  return Math.min(-1.5*decibels(volume), 60)/100
}

function volumeProfile(distance, dbOffset) {
  dbOffset = dbOffset || 0
  const R1 = 40
  const R2 = 15
  // let v = Math.sqrt(R0**2/(R0**2+d**2))
  let v = R2**2/(R2**2 + (Math.max(distance, R1)-R1)**2)
  v = v*10**(dbOffset/20)
  v = between(0, 1, v, 1)
  return v
}

function decibels(volume) {
  let db = 20*Math.log(volume)/Math.log(10)
  return db.toFixed(1)
}

function between(minimum, maximum, value, defaultValue=1) {
  if (typeof value === 'number' && !isNaN(value)) {
    return Math.max(Math.min(value, maximum), minimum)
  } else {
    return defaultValue
  }
}

export default view(NewRemoteVideo)