import React, { useState, useEffect } from "react"
import 'react-toastify/dist/ReactToastify.min.css'
// import '@fortawesome/fontawesome-free/css/all.min.css'
import './office.scss'
import {useHundred} from '../services/hundred_service'
import {view, store} from '@risingstack/react-easy-state'

import NewVideos from './new_videos'
import ChatRoom from './chat_room'
import OfficeMap from './office_map'
import SidePanel from './side_panel'

import useOffice from '../services/office_service'
import useLogin from "../services/login_service"
import useToken from '../services/token_service'
import SettingsPopup from './settings_popup'



function Office() {

    const {token} = useToken()
    const {logout, user} = useLogin()
    const {connect: openOfficeChannel, disconnect: closeOfficeChannel, snapshot} = useOffice(true)
    window.snapshot = snapshot
    const {connect, disconnect, hundredState} = useHundred()

    let state = store({
        isInRoom: false,
    })


    useEffect(() => {
        if (user && user.name) {
            openOfficeChannel(token)
        }
    }, [user])
    

    function onLogout() {
        hundredDisconnect() // TODO: Not sure if needed
        closeOfficeChannel()
        return logout()
    }

    useEffect(() => {
        window.onunload = disconnect
        return () => {
            disconnect()
            window.onunload = null
        }
    }, [])

    function hundredConnect() {
        connect()
        state.isInRoom = true
    }

    function hundredDisconnect() {
        disconnect()
        state.isInRoom = false
    }


    let videoButtons = !state.isInRoom ?
      <button onClick={hundredConnect} > Join Video </button> :
      <button onClick={hundredDisconnect} > Leave Video </button>

    let debugInfo = <>
        <div>
            <div style={{width: '50%', display: 'inline-block'}}>
                {
                    hundredState.participants.map((participant) => {
                        return <pre key={participant.identity}>{JSON.stringify(participant, null, 2)}</pre>
                    })
                }
            </div>
            <div style={{width: '50%', display: 'inline-block'}}>
                {
                    Object.entries(hundredState.volumes).map(([key, value]) => {
                        let result = {track: key, ...value}
                        return <pre key={key}>{JSON.stringify(result, null, 2)}</pre>
                    })
                }
            </div>
        </div>
    </>


    return <>
        <div className="frame" style={{height: '100vh', overflow: 'hidden', display: 'flex'}}>
            <div className="office" style={{flexGrow: 1, padding: '0.5rem 1rem', overflowY: 'auto'}}>

                <NewVideos />

                <div style={{display:'flex', justifyContent: 'center'}}>
                    <OfficeMap />
                </div>

                <button onClick={onLogout} className="button-outline">Log out</button>
                { videoButtons }
                <SettingsPopup />
                { token.match(/^bleh/) ? debugInfo : null }

            </div>

            <SidePanel >
                <ChatRoom />
            </SidePanel>

        </div>
    </>
}



export default view(Office)