import * as hundred from '@100mslive/hms-video-store';
import axios from 'axios'
import { store } from '@risingstack/react-easy-state'
import IndexArray from 'index-array'
window.IndexArray = IndexArray


const hms = new hundred.HMSReactiveStore();
const hmsStore = hms.getStore();
const hmsActions = hms.getActions();
hmsStore.subscribe(onPeerUpdate, hundred.selectPeers)
window.hmsActions = hmsActions

let hundredState = store({
  peers: [],
  participants: new IndexArray(),
  volumes: {},
})

let deviceState = store({
  audioOutputs: [],
  audioOutputId: '',
  audioInputs: [],
  audioInputId: '',
  videoInputs: [],
  videoInputId: '',
})

window.hundredState = hundredState


function useHundred() {
  let self = {
    hundredState: hundredState,
    deviceState: deviceState,
    connect,
    disconnect,
    attachVideo,
    enableAudio,
    enableVideo,
    setVolume,
    getDevices,
    setAudioOutputDevice,
    setAudioInputDevice,
    setVideoInputDevice,
  }

  function connect() {
    return axios.get('/api/rooms').then(({data}) => {
      let promise = hmsActions.join({userName: data.name, authToken: data.token})
      return promise
    })
  }

  function disconnect() {
    return hmsActions.leave()
      .then(() => {
        isVolumeReset = {}
      })
  }

  function attachVideo(videoTrack, videoElement) {
    return hmsActions.attachVideo(videoTrack, videoElement)
  }

  function enableAudio(enabled) {
    return hmsActions.setLocalAudioEnabled(enabled)
  }

  function enableVideo(enabled) {
    return hmsActions.setLocalVideoEnabled(enabled)
  }

  function setVolume(value = 0, audioTrack) {
    return hmsActions.setVolume(100 * value, audioTrack)
  }

  function getDevices() {
    let devices = hmsStore.getState(hundred.selectDevices)
    let selected = hmsStore.getState(hundred.selectLocalMediaSettings)

    deviceState.audioOutputs = devices.audioOutput
    deviceState.audioInputs = devices.audioInput
    deviceState.videoInputs = devices.videoInput
    deviceState.audioOuputId = selected.audioOutputDeviceId
    deviceState.audioInputId = selected.audioInputDeviceId
    deviceState.videoInputId = selected.videoInputDeviceId

    return {devices, selected}
  }

  function setAudioOutputDevice(deviceId) {
    hmsActions.setAudioOutputDevice(deviceId)
    deviceState.audioOutputId = deviceId
  }

  function setAudioInputDevice(deviceId) {
    return hmsActions.setAudioSettings({deviceId: deviceId}).then(() => {
      deviceState.audioInputId = deviceId
    })
  }

  function setVideoInputDevice(deviceId) {
    return hmsActions.setVideoSettings({deviceId}).then(() => {
      deviceState.videoOutputId = deviceId
    })
  }

  return self
}



// TODO: remove after debugging
function setVolumes(track, value, source, name=null) {
  console.log(`setVolumes ${value} for ${track} in ${source}`)
  if (!track) return
  hundredState.volumes[track] = {value, source, name}
}

let isVolumeReset = {}

// TODO: speed-up/memoize/hash this mapping
function onPeerUpdate(peers) {
  hundredState.peers = peers

  let mappedPeers = peers.reduce((result, peer) => {
    let audioTrack = peer.audioTrack
    if (audioTrack && !isVolumeReset[audioTrack]) {
      isVolumeReset[audioTrack] = true
      setVolumes(audioTrack, 0, 'init', peer.name)
      hmsActions.setVolume(0, audioTrack)
    }

    let id = peer.customerUserId
    if (result[id] && peer.joinedAt > result[id].joinedAt) {
      result[id] = peer
    } else if(!result[id]) {
      result[id] = peer
    }
    return result
  }, {})


  mappedPeers = Object.entries(mappedPeers).map(([identity, peer]) => {

    return {
      peerId: peer.id,
      identity: peer.customerUserId,
      name: peer.name,
      isLocal: peer.isLocal,
      videoTrack: peer.videoTrack,
      audioTrack: peer.audioTrack,
      joinedAt: peer.joinedAt,
    }
  })
  // console.log('updated participants')
  hundredState.participants = new IndexArray(...mappedPeers)
}


export {setVolumes, useHundred}