
function copyToClipboard(text) {
  let textArea = document.createElement('textarea')
  document.body.appendChild(textArea)
  textArea.style = 'opacity: 0; position: absolute; top: -100px;'
  textArea.value = text
  textArea.select()
  textArea.setSelectionRange(0, 9999)
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

export {copyToClipboard}