import React from 'react'
import './side_panel.scss'

function SidePanel(props) {

    return <div className="side-panel">
        {props.children}
    </div>
}


export default SidePanel