import React from "react"
import { useRef, useState } from 'react'
import useOffice from '../services/office_service'
import useLogin from '../services/login_service'
import Logger from '../services/logger_service'
import {view, store} from '@risingstack/react-easy-state'

import './office_map.scss'

const GAP = 5
const logger = Logger.get('OfficeMap')

function OfficeMap(props) {
  let boundary = useRef(null)

  const {persons, setMyPosition, ping, rooms} = useOffice()
  const {user} = useLogin()

  const state = store({localPosition: null})

  function onMove(e, room) {
    logger.info('room', room.id)
    let bounds = boundary.current.getBoundingClientRect()
    let newPosition = [e.clientX-bounds.x, e.clientY-bounds.y]
    state.localPosition = newPosition
    setMyPosition(newPosition)
  }

  function onPing(person) {
    if (user.person_id === person.id) {
      return
    }
    ping(person)
  }


  return <svg ref={boundary} height="400px" width="500px">

    <filter id="gblur">
      <feGaussianBlur stdDeviation={2} />
    </filter>

    <g>
      {rooms.map((room) => {
        let [x,y,width,height] = room.dimensions

        return <g className="room"
                  key={room.id}>
          <rect
            className="shadow"
            style={{filter: 'url(#gblur)'}}
            x={x+GAP+1}
            y={y+GAP+1}
            width={width-2*GAP}
            height={height-2*GAP}
          />
          <rect
            onClick={(e) => {onMove(e, room)}}
            style={{fill:'#ffffff',strokeWidth:'0.5px',stroke:'#555'}}
            x={x+GAP}
            y={y+GAP}
            width={width-2*GAP}
            height={height-2*GAP}
          />
        </g>
      })}
      {!!state.localPosition ?
        <circle
          style={{fill:'#66ddff', strokeWidth:'1px',stroke:'#333'}}
          r="5"
          cx={state.localPosition[0]}
          cy={state.localPosition[1]} />
        : null
      }



      {persons.filter((person) => !!person.position)
      .map((person) => {
        return <g className="remote"
                  key={person.id}
                  transform={`translate(${person.position[0]}, ${person.position[1]})`}>
          <circle
            className="shadow"
            style={{filter: 'url(#gblur)'}}
            r="10"
            cx={1}
            cy={1}
          />
          <circle
            onClick={() => onPing(person)}
            className="remote"
            style={{fill:'#66ff66'}}
            r="10"
          />

          <text
            className="remote"
            style={{fontFamily: 'arial', pointerEvents: 'none'}}
            x={-20}
            y={-12}
          >{person.name}</text>
        </g>
      })}

    </g>
  </svg>
}


export default view(OfficeMap)