import React, {useEffect} from 'react'
import {view, store} from '@risingstack/react-easy-state'
import {useHundred} from '../services/hundred_service'

import './all_videos.scss'
import useOffice from "../services/office_service"
import NewLocalVideo from './new_local_video'
import NewRemoteVideo from './new_remote_video'
import useLogin from "../services/login_service";

import Logger from '../services/logger_service'

const logger = Logger.get('AllVideo')

function NewVideos() {

  const {user} = useLogin()
  const {officeState} = useOffice()
  const {hundredState} = useHundred()

  const state = store({
    sortedParticipants: []
  })

  // autoEffect causes this to crash in production (not in development)
  useEffect(() => {
    let chosenParticipants = chooseParticipants(hundredState.participants, officeState.persons)
    state.sortedParticipants = chosenParticipants.sort((a, b) => {
      return distance(a, officeState.persons) - distance(b, officeState.persons)
    })
  }, [hundredState.participants, officeState.persons])

  function chooseParticipants(participants, persons) {
    let currentPerson = persons.fetch({id: user.person_id})
    if (!currentPerson) return []

    let chosenParticipants = []
    participants.forEach((participant) => {
      let person = persons.fetch({id: participant.identity})

      if (person && person.subscribed) {
        chosenParticipants.push(participant)
      }
    })
    return chosenParticipants
  }

  return <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
    <NewLocalVideo />
    {state.sortedParticipants.map((participant) => {
      return <NewRemoteVideo
        key={participant.identity}
        participant={participant}
        // videoTrack={participant.videoTrack} // Necessary because it is watched by RemoteVideo in useEffect
        // audioTrack={participant.audioTrack}
      />
    })}
  </div>

}

function distance(participant, persons) {
  let currentPerson = persons.fetch({id: parseInt(participant.identity)})
  return currentPerson?.distance ?? 0
}

export default view(NewVideos)