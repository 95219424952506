import {useEffect} from 'react'
import {store, view} from '@risingstack/react-easy-state'
import axios from 'axios'

const loginStore = store({loggedIn: null, user: {}})

function useLogin(master = false) {

    if (master) {
        useEffect(() => {
            axios.get('/user')
            .then(onLogin)
            .catch((response) => {
                loginStore.loggedIn = false
                loginStore.user = {}
            })
        }, [])
    }

    function onLogin(response) {
        if (!response.data.id) {
            loginStore.loggedIn = false
            loginStore.user = {}
        } else {
            loginStore.loggedIn = true
            loginStore.user = response.data
        }
    }

    function guestLogin(username, token) {
        return axios.post('/users/login',
          {name: username, is_guest: true, token})
        .then(onLogin)
    }

    function login(email, password) {
        return axios.post('/users/login',
          {user: {email, password}})
        .then(onLogin)
    }

    function logout() {
        return axios.get('/users/logout')
        .then((response) => {
            loginStore.loggedIn = false
            loginStore.user = {}
        })
    }


    return {...loginStore, guestLogin, login, logout}
}


export default useLogin