import React, {useState, useEffect} from "react"
import 'react-toastify/dist/ReactToastify.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import {Route, Switch, Redirect} from 'react-router-dom'
import {ToastContainer} from "react-toastify";
import {view} from '@risingstack/react-easy-state'

import './virtual_office.scss'

import Login from './components/login'
import Office from './components/office'
import OfficeEditor from './components/office_editor'
import OfficeList from './components/office_list'
import Register from './components/register'

import useLogin from "./services/login_service";


function VirtualOffice() {

  const {loggedIn} = useLogin(true)

  let routes = null
  if (loggedIn) {
    routes = <Switch>
      <Route path="/o/:token" component={Office} />
      <Route exact path="/offices" component={OfficeList} />
      <Route path="/offices/:token" component={OfficeEditor} />
      <Route path="*"><Redirect to="/offices" /></Route>
    </Switch>
  } else if (loggedIn === null) {
    routes = <p>Loading...</p>
  } else {
    routes = <Switch>
      <Route exact path="/register" component={Register} />
      <Route path="*" component={Login} />
    </Switch>
  }

  return <>
    <ToastContainer progressClassName={'toast-progress-bar'} />
    {routes}
  </>
}


export default view(VirtualOffice)