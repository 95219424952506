import React, { useEffect, useRef } from 'react'
import c from 'classnames'
import {view, store, autoEffect} from '@risingstack/react-easy-state'

import './local_video.scss'
import {useHundred} from '../services/hundred_service'
import Icon from './icon'
import useLogin from "../services/login_service"
import SettingsPopup from './settings_popup'

function LocalVideo() {
  let container = useRef()
  let video = useRef()

  const {hundredState, attachVideo, enableAudio, enableVideo} = useHundred()

  const state = store({
    micOn: true,
    videoOn: true,
    localParticipant: null,
    active: false,
  })

  autoEffect(() => {
    state.localParticipant = hundredState.participants.find((participant) => {
      return participant.isLocal
    })
  })

  autoEffect(() => {
    let videoTrack = state.localParticipant?.videoTrack
    state.active = !!videoTrack
    if (videoTrack) {
      attachVideo(videoTrack, video.current)
    }
  }, [state.localParticipant?.videoTrack, state.videoOn]) // re-attach video on unmute


  const {user} = useLogin()

  function toggleVideo() {
    state.videoOn = !state.videoOn
    enableVideo(state.videoOn)
  }

  function toggleMic() {
    state.micOn = !state.micOn
    enableAudio(state.micOn)
  }


  return <div className="local-video-container">
    <div className="local-video-overlay">
      <div className="overlay-text">{user.name}</div>
      <div style={{flexGrow: 1}}></div>
      <div style={{display: 'flex'}}>
        <button className={c({icon: true, mute: true, active: !state.micOn})}
                onClick={toggleMic}>
          <Icon icon={state.micOn ? 'micOn' : 'micOff'} />
        </button>
        <button className={c({icon: true, mute: true, active: !state.videoOn})}
                onClick={toggleVideo}>
          <Icon icon={state.videoOn ? 'videoOn' : 'videoOff'} />
        </button>

        <div style={{flexGrow: 1}}></div>
        <div style={{display: 'flex'}}>
          {state.active ?  <SettingsPopup /> : null}
        </div>
      </div>
    </div>

    <div className="local-video" ref={container}>
      <video className={c({hidden: !state.localParticipant})} ref={video} autoPlay muted playsInline />
    </div>
  </div>
}

export default view(LocalVideo)