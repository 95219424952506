import React from 'react'
import { useState } from 'react'

import useLogin from '../services/login_service'
import {toast} from "react-toastify";
import useQueryParams from "../services/query_params_service";
import useToken from '../services/token_service'


const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

function Login() {

    const {guestLogin, login} = useLogin()
    const {token} = useToken()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [joining, setJoining] = useState(false)

    const [showPassword, setShowPassword] = useState(false)
    const [isGuest, setIsGuest] = useState(!!token)

    function reset() {
        setName('')
        setEmail('')
        setPassword('')
        setShowPassword(false)
        setJoining(false)
    }

    function toggleGuestLogin() {
        setIsGuest(!isGuest)
    }

    function submitEmail() {
        if (!email.match(EMAIL_REGEX)) {
            toast.error('The email address you entered is invalid.')
            return
        }
        setShowPassword(!showPassword)
    }

    function cancelPassword() {
        setPassword('')
        setShowPassword(!showPassword)
    }

    function onJoin(name) {
        let trimmedName = name.trim()
        setName(trimmedName)
        if (trimmedName.length < 3 || trimmedName.length > 32) {
            toast.error('Your name must have between 3 and 32 characters.')
        } else if (!joining) {
            setJoining(true)
            guestLogin(trimmedName, token)
            .then(() => {
                reset()
            })
        }
    }

    function onLogin() {
        if (!joining) {
            setJoining(true)
            login(email, password)
            .then(() => {
                reset()
            })
            .catch(() => {
                reset()
                toast.error('Unable to sign in.')
            })
        }
    }


    return <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div style={{height: '20%'}}></div>
        {isGuest &&
            <fieldset style={{alignSelf: 'center'}}>
                <label>Name</label>
                <input
                  type="text"
                  style={{width: '30rem'}}
                  value={name}
                  onChange={(e) => {
                      setName(e.target.value)
                  }}
                  onKeyDown={(e) => e.key ===  'Enter' && onJoin(name)}/>
                <div style={{display: 'flex'}}>
                    <button className="button-clear" onClick={toggleGuestLogin}> Full Login</button>
                    <div style={{flexGrow: 1}}/>
                    <button onClick={() => onJoin(name)}> Join</button>
                </div>
            </fieldset>
        }

        {!isGuest && !showPassword &&
          <fieldset style={{alignSelf: 'center'}}>
              <label>Email</label>
              <input
                type="email"
                style={{width: '30rem'}}
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
                onKeyDown={(e) => e.key ===  'Enter' && submitEmail(email)}/>
              <div style={{display: 'flex'}}>
                  <div style={{flexGrow: 1}}/>
                  <button onClick={() => submitEmail(email)}> Log in</button>
              </div>
          </fieldset>
        }

        {!isGuest && showPassword &&
          <fieldset style={{alignSelf: 'center'}}>
              <label>Password</label>
              <input
                type="password"
                style={{width: '30rem'}}
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
                onKeyDown={(e) => e.key ===  'Enter' && onLogin()}/>
              <div style={{display: 'flex'}}>
                  <button className="button-clear" onClick={() => cancelPassword()}>Cancel</button>
                  <div style={{flexGrow: 1}}/>
                  <button onClick={() => onLogin()}> Log in</button>
              </div>
          </fieldset>
        }


    </div>
}

export default Login