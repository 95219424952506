import pingSound from '../assets/virtual-office.ogg'

let audio = {
  ping: new Audio(pingSound)
}

function play(name) {
  audio[name].play()
}


export default play