import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Icon from "./icon";
import {copyToClipboard} from '../services/clipboard_service'
import {useHistory} from 'react-router-dom'
import Logger from '../services/logger_service'

const logger = Logger.get('OfficeList')


function OfficeList() {

  const history = useHistory()
  const [offices, setOffices] = useState([])
  const [name, setName] = useState('')

  useEffect(() => {
    refresh()
  },[])

  function refresh() {
    axios.get('/api/offices')
    .then(({data}) => {
      setOffices(data)
    })
  }

  function createOffice() {
    axios.post('/api/offices', {name: name})
    .then(() => {
      setName('')
      logger.info('all good')
      refresh()
    })
  }

  function deleteOffice(id) {
    axios.delete(`/api/offices/${id}`)
    .then(() => {
      logger.info('deleted!', id)
      refresh()
    })
  }

  return <>
    <table>
      <thead>
      <tr>
        <th>Name</th>
        <th>Token</th>
        <th>Link</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {offices.map((office) => {
        let link = `${window.location.origin}/o/${office.token}`

        return <tr key={office.id}>
          <td>{office.name}</td>
          <td>{office.token}</td>
          <td>
            <a href={link}>{link}</a>
          </td>
          <td>
            <button className="icon" onClick={() => copyToClipboard(link)}>
              <Icon icon="clipboard"/>
            </button>
            <button className="icon" onClick={() => history.push(`/offices/${office.token}`)}>
              <Icon icon="edit"/>
            </button>
            <button className="icon" onClick={() => deleteOffice(office.id)}>
              <Icon icon="delete"/>
            </button>
          </td>
        </tr>
      })}
      </tbody>
    </table>
    <fieldset>
      <label>name</label>
      <input
        type="text"
        value={name}
        onChange={(e) => {setName(e.target.value)}} />
      <button onClick={createOffice}>Create</button>
    </fieldset>

  </>
}

export default OfficeList