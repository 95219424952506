import React from 'react'
import _ from 'lodash'

import './icon.scss'

let iconMap = {
    camera:     "fas fa-camera",
    clipboard:  "far fa-copy",
    delete:     "fas fa-trash-alt",
    edit:       "fas fa-edit",
    mute:       "fas fa-microphone",
    muted:      "fas fa-microphone-slash",
    micOn:      "fas fa-microphone",
    micOff:     "fas fa-microphone-slash",
    videoOn:    "fas fa-video",
    videoOff:   "fas fa-video-slash",
    volume:     "fas fa-volume-down",
}

function getIcon(key) {
    let result = iconMap[key]
    return result || `fas fa-${_.kebabCase(key)}`
}


function Icon(props) {

    if (props.icon) {
        return <i className={getIcon(props.icon) + ' icon'} />
    } else {
        return <i className={props.className + ' icon'} />
    }
}


export default Icon