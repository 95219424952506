import ReactDOM from 'react-dom'
import React, { useState, useEffect } from "react"
import { view, store, autoEffect } from '@risingstack/react-easy-state'
import { usePopper } from "react-popper"

import './remote_video_popup.scss'
import c from "classnames";
import Icon from "./icon";
import {useHundred} from '../services/hundred_service'

function SettingsPopup(props) {

  // Ugly popper stuff
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{
      name: 'offset', options: {offset: [0,8]}
    }, {
      name: 'preventOverflow', options: {mainAxis: false, altAxis: false}
    }],
    placement: 'left'
  });

  const {deviceState, getDevices, setAudioOutputDevice, setAudioInputDevice, setVideoInputDevice} = useHundred()

  const state = store({
    visible: false
  })

  autoEffect(() => {
    if (state.visible) {
      getDevices()
    }
  })

  function toggleVisible() {
    state.visible = !state.visible
  }

  let popupContent = (
    <div className="popup-menu" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <label>Audio output</label>
      <select value={deviceState.audioOutputId}
              onChange={(e) => {setAudioOutputDevice(e.target.value)}}>
        {deviceState.audioOutputs.map((device) => {
          return <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
        })}
      </select>
      <label>Audio input</label>
      <select value={deviceState.audioOutputId}
              onChange={(e) => {setAudioInputDevice(e.target.value)}}>
        {deviceState.audioInputs.map((device) => {
          return <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
        })}
      </select>
      <label>Video input</label>
      <select value={deviceState.videoInputId}
              onChange={(e) => {setVideoInputDevice(e.target.value)}}>
        {deviceState.videoInputs.map((device) => {
          return <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
        })}
      </select>
    </div>
  )

  let popup = ReactDOM.createPortal(popupContent, document.getElementById('popup'))

  return <>
    <button className="icon options" ref={setReferenceElement} onClick={toggleVisible}>
      <Icon icon="ellipsis-v"/>
    </button>

    {state.visible ?
      popup
      : null
    }
  </>
}



export default view(SettingsPopup)