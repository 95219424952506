import React, {useState, useEffect, useRef} from 'react'
import {v4 as uuid} from 'uuid'
import Logger from '../../services/logger_service'

import {view, store, autoEffect} from '@risingstack/react-easy-state'


const GRID = 12
const [WIDTH, HEIGHT] = [500, 400]
const logger = Logger.get('OfficeLayoutEditor')

const round = Math.round

function gridRound(pos) {
  let x = (round(pos[0]/WIDTH*GRID)*WIDTH/GRID)
  let y = (round(pos[1]/HEIGHT*GRID)*HEIGHT/GRID)
  return [x,y]
}

function gridCoord(pos) {
  return [round(pos[0]/WIDTH*GRID-0.5), round(pos[1]/HEIGHT*GRID-0.5)]
}

function OfficeLayoutEditor(props) {

  const state = props.state
  const boundary = useRef(null)


  function onMove(e) {
    let bounds = boundary.current.getBoundingClientRect()
    state.position = [round(e.clientX - bounds.x), round(e.clientY - bounds.y)]

    if (state.action.type === 'resize') {
      let [posX, posY] = gridRound(state.position)
      let dimensions = state.action.room.dimensions
      let [x, y, width, height] = dimensions
      if (state.action.hside === 'left') {
        dimensions[2] = width - (posX - x)
        dimensions[0] = posX
      }
      if (state.action.hside === 'right') {
        dimensions[2] = posX - x
      }
      if (state.action.vside === 'top') {
        dimensions[3] = height - (posY - y)
        dimensions[1] = posY
      }
      if (state.action.vside === 'bottom') {
        dimensions[3] = posY - y
      }
    } else if (state.action.type === 'drag') {
      // let dimensions = state.action.room.dimensions
      let startDimensions = state.action.startDimensions
      let start = state.action.start
      let [deltaX, deltaY] = gridRound([state.position[0] - start[0], state.position[1] - start[1]])
      state.action.room.dimensions = [
        startDimensions[0] + deltaX,
        startDimensions[1] + deltaY,
        startDimensions[2],
        startDimensions[3]
      ]
    } else if (state.action.type === 'draw') {
      let startCoord = state.action.startCoord
      let endCoord = gridCoord(state.position)
      let rect = [Math.min(startCoord[0], endCoord[0]), Math.min(startCoord[1], endCoord[1]),
        Math.abs(startCoord[0] - endCoord[0]) + 1, Math.abs(startCoord[1] - endCoord[1]) + 1]
      state.action.room.dimensions = [
        rect[0] * WIDTH / GRID, rect[1] * HEIGHT / GRID, rect[2] * WIDTH / GRID, rect[3] * HEIGHT / GRID
      ]
    }
  }

  function startResize(e, room) {
    let hside = null
    let vside = null
    let [x,y,width,height] = room.dimensions
    if (within(x, state.position[0], 5)) {
      hside = 'left'
    } else if (within(x+width, state.position[0], 5)) {
      hside = 'right'
    }
    if (within(y, state.position[1], 5)) {
      vside = 'top'
    } else if (within(y+height, state.position[1], 5)) {
      vside = 'bottom'
    }

    state.action = {type: 'resize', hside, vside, room: room}
    logger.info('resize', state.action)
  }

  function within(first, second, delta) {
    return Math.abs(first-second) <= delta
  }

  function startDrag(e, room) {
    state.action = {type: 'drag', room: room, start: [...state.position], startDimensions: room.dimensions}
    logger.info('drag', state.action)
  }

  function startDraw(e) {
    let startCoord = gridCoord(state.position)
    let newRoom = {uuid: uuid(), from_rooms: [],
      dimensions: [startCoord[0]*WIDTH/GRID, startCoord[1]*HEIGHT/GRID, WIDTH/GRID, HEIGHT/GRID]
    }
    state.office.rooms.push(newRoom)
    state.action = {type: 'draw', room: newRoom, startCoord}
    logger.info('draw', state.action)
  }

  function onRemove(e, room) {
    e.preventDefault()
    let roomIndex = state.office.rooms.indexOf(room)
    logger.info(roomIndex)
    state.office.rooms.splice(roomIndex, 1)
  }


  return <>
    <svg
      ref={boundary}
      height={HEIGHT+'px'} width={WIDTH+'px'}
      onMouseMove={onMove}>

      <filter id="gblur">
        <feGaussianBlur stdDeviation={2} />
      </filter>

      <rect
        x={0} y={0} width={WIDTH} height={HEIGHT}
        style={{fill: '#eee'}}
        onMouseDown={startDraw}
      />

      <g>
        {state.office.rooms.map((room) => {
          let [x,y,width,height] = room.dimensions

          return <React.Fragment key={room.uuid}>
            <rect
              onMouseDown={(e) => {startDrag(e, room)}}
              onContextMenu={(e) => {onRemove(e, room)}}
              style={{fill:'#ffffff', stroke:'none', cursor: 'grab'}}
              x={x} y={y} width={width} height={height}
            />
            <rect
              onMouseDown={(e) => {startResize(e, room)}}
              style={{fill:'none',strokeWidth:'6px', stroke:'#333', cursor: 'move'}}
              x={x+3} y={y+3} width={width-6} height={height-6}
            />
          </React.Fragment>
        })}

      </g>
    </svg>
  </>
}

export default view(OfficeLayoutEditor)