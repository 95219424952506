import React, {useState, useEffect, useRef} from 'react'
import {v4 as uuid} from 'uuid'

import {view, store, autoEffect} from '@risingstack/react-easy-state'


const [WIDTH, HEIGHT] = [500, 400]
const round = Math.round

function OfficeConnectionEditor(props) {

  const state = props.state
  const boundary = useRef(null)


  function onMove(e) {
    let bounds = boundary.current.getBoundingClientRect()
    state.position = [round(e.clientX - bounds.x), round(e.clientY - bounds.y)]
  }

  function onRemove(e, room) {
    e.preventDefault()
    let roomIndex = state.office.rooms.indexOf(room)
    state.office.rooms.splice(roomIndex, 1)
  }

  function onHover(room) {
    if (state.action.type !== 'connect') {
      state.action = {type: 'hover', room}
    } else if (state.action.type === 'connect') {
      state.action.toRoom = room
    }
  }

  function onLeave(room) {
    if (state.action.type === 'hover') {
      state.action = {}
    }
  }

  function startConnect(room) {
    state.action = {type: 'connect', fromRoom: room, toRoom: null}
  }

  function endConnect(room) {
    room.from_rooms.push(state.action.fromRoom.uuid)
    setTimeout(() => {
      state.action = {type: 'hover', room}
    }, 0)
  }


  return <>
    <svg
      ref={boundary}
      height={HEIGHT+'px'} width={WIDTH+'px'}
      onMouseMove={onMove}>

      <filter id="gblur">
        <feGaussianBlur stdDeviation={2} />
      </filter>

      <rect
        x={0} y={0} width={WIDTH} height={HEIGHT}
        style={{fill: '#eee'}}
      />

      <g>
        {state.office.rooms.map((room) => {
          let [x,y,width,height] = room.dimensions
          let color = '#ffffff'
          if (state.action.type === 'hover') {
            let isHover = state.action.room === room
            let sendsToHover = state.action.room.from_rooms.indexOf(room.uuid) >= 0
            let getsFromHover = room.from_rooms.indexOf(state.action.room.uuid) >= 0

            if (sendsToHover && getsFromHover) {
              color = '#5bff5f'
            } else if (isHover) {
              color = '#befdc1'
            } else if (sendsToHover) {
              color = '#bee7fd'
            } else if (getsFromHover) {
              color = '#fdf5be'
            }
          } else if (state.action.type === 'connect') {
            let isFrom = state.action.fromRoom === room
            let isTo = state.action.toRoom === room

            if (isFrom && isTo) {
              color = '#5bff5f'
            } else if (isFrom) {
              color = '#bee7fd'
            } else if (isTo) {
              color = '#fdf5be'
            }
          }


          return <React.Fragment key={room.uuid}>
            <rect
              onMouseEnter={() => onHover(room)}
              onMouseLeave={() => onLeave(room)}
              onMouseDown={(e) => {startConnect(room)}}
              onMouseUp={(e) => {endConnect(room)}}
              onContextMenu={(e) => {onRemove(e, room)}}
              style={{fill: color, stroke:'none', cursor: 'pointer'}}
              x={x} y={y} width={width} height={height}
            />
            <rect
              onMouseEnter={() => onHover(room)}
              onMouseLeave={() => onLeave(room)}
              style={{fill:'none',strokeWidth:'6px', stroke:'#333'}}
              x={x+2} y={y+2} width={width-4} height={height-4}
            />
          </React.Fragment>
        })}

      </g>
    </svg>
  </>
}

export default view(OfficeConnectionEditor)