import {useParams} from 'react-router-dom'

const TOKEN_REGEX = /\/o\/([\w-_]+)/

function useToken() {
  let params = useParams()

  let token
  if (params[0] && params[0].match(TOKEN_REGEX)) {
    token = params[0].match(TOKEN_REGEX)[1]
  } else if(params.token) {
    token = params.token
  }

  return {token}
}

export default useToken